import React from "react";
import { createRoot } from "react-dom/client";
import { Observable, from } from "rxjs";
import { pluck, flatMap } from "rxjs/operators";

export function sendBulkEmail(options) {
  if (typeof options !== "object") {
    throw new Error(`Cannot send bulk email without an options object`);
  }

  if (!options.jql) {
    throw new Error(`Cannot send bulk email without options.jql`);
  }

  const el = document.createElement("div");
  document.body.appendChild(el);

  return from(import(/* webpackChunkName: "send-bulk-email" */ "./send-bulk-email.component.js")).pipe(
    pluck("default"),
    flatMap((SendBulkEmail) => {
      return Observable.create((observer) => {
        const root = createRoot(el);
        root.render(
          <SendBulkEmail
            options={options}
            finish={() => finished(true)}
            cancel={() => finished(false)}
            updatedClients={updatedClients}
          />
        );

        function finished(sent) {
          root.unmount();
          if (document.body.contains(el)) {
            document.body.removeChild(el);
          }
          observer.next({ sent });
          observer.complete();
        }

        function updatedClients() {
          // So that the client list knows to refresh its list.
          observer.next({ updatedClients: true });
        }
      });
    })
  );
}
